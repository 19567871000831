import React, { FunctionComponent } from "react";
import { mergeClassNames } from "../../helpers";
import ButtonPrimary from "../Buttons/ButtonPrimary";
import CopyLink from "./CopyLink";

type CopyButtonProps = {
  className?: string;
  to: string;
  external?: boolean;
  isGilead?: boolean;
  openInNewTab?: boolean;
  jump?: boolean;
  tracking: Function;
  copyTracking: Function;
};

const CopyButton: FunctionComponent<CopyButtonProps> = ({
  className,
  external,
  isGilead,
  children,
  to,
  tracking,
  copyTracking,
}) => {
  return (
    <div
      className={mergeClassNames(className, "flex flex-col md:items-center")}
    >
      <ButtonPrimary
        to={to}
        external={external}
        isGilead={isGilead}
        tracking={tracking}
      >
        {children}
      </ButtonPrimary>
      <CopyLink to={to} tracking={copyTracking} />
    </div>
  );
};
export default CopyButton;
