import React, { useEffect } from "react";
import Layout from "../../components/Layout";
import SEO from "../../components/SEO";
import HeroImage from "../../components/HeroImage/HeroImage";
import Slab from "../../components/Slab";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import heroBox from "../../images/hepatitis-b-management/patient-education/hero-box.svg";
import heroBoxWithoutAnimation from "../../images/hepatitis-b-management/patient-education/hero-box-without-animation.svg";
import Subheading from "../../components/Subheading/Subheading";
import CopyButton from "../../components/CopyButton/CopyButton";
import EducationLink from "../../components/EducationLink";
import Link from "../../components/Link/Link";
import AssetCardGroup from "../../components/AssetCard/AssetCardGroup";
import LivingWithHepBBrochure from "../../partials/AssetCards/LivingWithHepBBrochure";
import tracker from "../../tracker";
import ImageWrapper from "../../components/ImageWrapper";

const PatientEducation = () => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(
        relativePath: {
          eq: "hepatitis-b-management/patient-education/hero.jpg"
        }
      ) {
        ...HeroQuery
      }
      testing: file(
        relativePath: {
          eq: "hepatitis-b-management/patient-education/hepb-com-testing.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 230) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      treatment: file(
        relativePath: {
          eq: "hepatitis-b-management/patient-education/hepb-com-treatment.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 230) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      multilingual: file(
        relativePath: {
          eq: "hepatitis-b-management/patient-education/hepb-com-multilingual.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 230) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      footerMan: file(relativePath: { eq: "footer-man.png" }) {
        childImageSharp {
          fluid(maxWidth: 328) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      page: trackingJson(
        id: { eq: "hepatitis-b-management/patient-education" }
      ) {
        subject
      }
      viewWebpageHero: trackingJson(id: { eq: "viewWebpageHero" }) {
        ...TrackingQuery
      }
      visitHepBButton: trackingJson(id: { eq: "visitHepBButton" }) {
        ...TrackingQuery
      }
      visitHepBCopy: trackingJson(id: { eq: "visitHepBCopy" }) {
        ...TrackingQuery
      }
      gotoWebsiteButton: trackingJson(id: { eq: "gotoWebsiteButton" }) {
        ...TrackingQuery
      }
      gotoWebsiteCopy: trackingJson(id: { eq: "gotoWebsiteCopy" }) {
        ...TrackingQuery
      }
      viewWebpageButton: trackingJson(id: { eq: "viewWebpageButton" }) {
        ...TrackingQuery
      }
      viewWebpageMultilingual: trackingJson(
        id: { eq: "viewWebpageMultilingual" }
      ) {
        ...TrackingQuery
      }
      viewWebpageCopy: trackingJson(id: { eq: "viewWebpageCopy" }) {
        ...TrackingQuery
      }
      reviewResourcesButton: trackingJson(id: { eq: "reviewResourcesButton" }) {
        ...TrackingQuery
      }
      reviewResourcesCopy: trackingJson(id: { eq: "reviewResourcesCopy" }) {
        ...TrackingQuery
      }
      downloadPatientBrochure: trackingJson(
        id: { eq: "downloadPatientBrochure" }
      ) {
        ...TrackingQuery
      }
    }
  `);
  useEffect(() => {
    window.dataLayer.push(data.page);
  }, []);
  return (
    <Layout>
      <SEO
        title="Chronic Hepatitis B Patient Education"
        description="Download hepatitis B resources designed to give your patients an overview of the virus, transmission methods, long term management and more."
      />
      <HeroImage
        headLine="Education for your CHB patients."
        content={
          <div className="text-center md:text-left">
            <Link
              className="text-green md:text-white underline"
              to="https://hepb.com"
              external
              isGilead
              tracking={tracker(data.viewWebpageHero)}
            >
              HepB.com
            </Link>{" "}
            is a Gilead patient education website about hepatitis&nbsp;B
            (hep&nbsp;B). Share it with patients interested in learning about
            topics ranging from the basics about the hep B virus to living with
            chronic hep&nbsp;B (CHB).
          </div>
        }
        footer={
          <CopyButton
            to="https://www.hepb.com"
            external
            isGilead
            className="text-green md:text-white text-center md:text-left md:flex-row"
            tracking={tracker(data.visitHepBButton)}
            copyTracking={tracker(data.visitHepBCopy)}
          >
            Visit HepB.com
          </CopyButton>
        }
        fluidQuery={data.hero.childImageSharp.fluid}
        boxSrc={heroBox}
        boxSrcWithoutAnimation={heroBoxWithoutAnimation}
        imagePosition={{ desktop: { x: 60, y: 10 }, mobile: { x: 72, y: 50 } }}
      />
      <Slab>
        <div className="flex flex-col -mx-4 md:flex-row md:flex-wrap">
          <EducationLink
            fluid={data.testing.childImageSharp.fluid}
            maxWidth={230}
            title="Testing and Diagnosis"
            body="Patients can learn about risk factors for chronic hepatitis B, how hep B is diagnosed, and more."
            to="https://www.hepb.com/hep-b-testing-diagnosis/risk-factors"
            alt="Preview of HepB.com testing page"
            cta="Go to Website"
            isGilead
            tracking={tracker(data.gotoWebsiteButton)}
            copyTracking={tracker(data.gotoWebsiteCopy)}
          />
          <EducationLink
            fluid={data.treatment.childImageSharp.fluid}
            maxWidth={230}
            title="Considering Treatment"
            body="This overview will help patients understand what options may be available for treating chronic hepatitis B."
            to="https://www.hepb.com/considering-hep-b-treatment"
            cta="View Web Page"
            alt="Preview of HepB.com treatment page"
            isGilead
            tracking={tracker(data.viewWebpageButton)}
            copyTracking={tracker(data.viewWebpageCopy)}
          />
          <EducationLink
            fluid={data.multilingual.childImageSharp.fluid}
            maxWidth={230}
            title="Multilingual patient education"
            body={
              <>
                Visit{" "}
                <Link
                  className="text-green underline"
                  to="https://www.hepb.com"
                  external
                  isGilead
                  tracking={tracker(data.viewWebpageMultilingual)}
                >
                  HepB.com
                </Link>{" "}
                to download the <em>Living with Hepatitis B</em> brochure in a
                variety of languages.
              </>
            }
            to="https://www.hepb.com/resources/brochure"
            cta="Review Resources"
            alt="Preview of HepB.com multilingual page"
            isGilead
            tracking={tracker(data.reviewResourcesButton)}
            copyTracking={tracker(data.reviewResourcesCopy)}
          />
        </div>
      </Slab>
      <div className="container flex flex-col md:flex-row">
        <div className="w-full md:w-2/3 pt-16 pb-8">
          <Subheading level={3}>Information for your patients</Subheading>
          <AssetCardGroup>
            <LivingWithHepBBrochure
              className="md:w-3/4"
              headline="Living with Hepatitis B"
              content="Written in patient-friendly language, this overview of hepatitis B includes virus transmission methods,  long-term chronic hep B management, and more."
              tracking={tracker(data.downloadPatientBrochure)}
            />
          </AssetCardGroup>
        </div>
        <div className="w-full md:w-1/3 self-end">
          <div className="-mb-64 md:-mb-8 lg:ml-32">
            <ImageWrapper maxWidth={328}>
              <Img fluid={data.footerMan.childImageSharp.fluid} />
            </ImageWrapper>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default PatientEducation;
