import React, { FunctionComponent, useState, useRef } from "react";
import { ReactComponent as LinkIcon } from "../../images/icons/link.svg";
import { mergeClassNames } from "../../helpers";
import "./CopyLink.scss";

const POPUP_TIMEOUT = 2000;
const ANIMATION_TIMEOUT = 300;

type Props = {
  to: string;
  className?: string;
  tracking: Function;
};
const CopyLink: FunctionComponent<Props> = ({ to, className, tracking }) => {
  const field = useRef<HTMLInputElement>(null);
  const [copied, setCopied] = useState(false);
  const [visible, setVisible] = useState(false);

  function show() {
    setVisible(true);
    setTimeout(() => {
      setCopied(true);
      setTimeout(() => {
        hide();
      }, POPUP_TIMEOUT);
    }, ANIMATION_TIMEOUT);
  }
  function hide() {
    setCopied(false);
    setTimeout(() => {
      setVisible(false);
    }, ANIMATION_TIMEOUT);
  }

  function copyLink() {
    tracking();
    let success = false;
    if (field && field.current) {
      field.current.select();
      success = document.execCommand("copy");
      window.getSelection()?.removeAllRanges();
    }
    if (success) {
      show();
    }
  }

  return (
    <span
      className={mergeClassNames(
        className,
        "CopyLink m-4 pt-4 align-middle relative"
      )}
    >
      <span
        className="font-bold antialiased underline cursor-pointer"
        onClick={copyLink}
      >
        <LinkIcon className="fill-current inline mr-1" />
        Copy link
      </span>

      <div
        className={`CopyLink__Popup ${copied &&
          "CopyLink__Popup--copied"} ${visible &&
          "CopyLink__Popup--visible"} bg-black text-white absolute bottom-0 p-4 text-sm text-center border shadow`}
      >
        <div className="text-bold">Link copied to clipboard</div>
        <div className="text-white-50">{to}</div>
      </div>
      <input className="CopyLink__Field" ref={field} value={to} readOnly />
    </span>
  );
};
export default CopyLink;
