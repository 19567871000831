import React, { FunctionComponent } from "react";
import Img, { FluidObject } from "gatsby-image";
import Paragraph from "./Paragraph";
import CopyButton from "./CopyButton/CopyButton";
import ImageWrapper from "./ImageWrapper";

type Props = {
  fluid: FluidObject;
  title: string;
  body: string | JSX.Element;
  to: string;
  alt: string;
  cta: string;
  isGilead?: boolean;
  tracking: Function;
  copyTracking: Function;
  maxWidth: number;
};
const EducationLink: FunctionComponent<Props> = ({
  alt,
  body,
  cta,
  fluid,
  title,
  to,
  isGilead,
  tracking,
  copyTracking,
  maxWidth,
}) => {
  return (
    <div className="w-full md:w-1/3">
      <div className="w-full text-center px-2 h-full flex flex-col pb-16">
        <div className="px-16 pb-4">
          <ImageWrapper maxWidth={maxWidth}>
            <Img fluid={fluid} alt={alt} />
          </ImageWrapper>
        </div>
        <h3 className="font-bold antialiased">{title}</h3>
        <Paragraph className="flex-grow pb-4">{body}</Paragraph>
        <CopyButton
          className="flex-col text-green"
          to={to}
          external
          isGilead={isGilead}
          tracking={tracking}
          copyTracking={copyTracking}
        >
          {cta}
        </CopyButton>
      </div>
    </div>
  );
};
export default EducationLink;
